import axios from 'axios'
import router from '@/router'

export async function post (url, data, success) {
  const options = {
    method: 'POST',
    data
  }
  const res = await request(url, options)
  if (res.data.message === '当前未登录，请先登录账号') {
    router.push('/login/login')
  }
  success(res)
}

export async function get (url, params, success) {
  const options = {
    method: 'GET',
    params
  }
  success(await request(url, options))
}

export function request (url, options) {
  const token = localStorage.getItem('token')
  const defaultOptions = {
    headers: {
      token
    },
    withCredentials: true,
    url: url,
    baseURL: '/api'
  }
  const newOptions = { ...options, ...defaultOptions }
  return axios.request(newOptions)
    .then(checkStatus(url, newOptions))
    .catch(error => {
      checkStatus(url, newOptions, error.response)
    })
}

let isRefreshing = true
function checkStatus (url, options, response) {
  if (response && response.status === 401) {
    // 刷新token的函数,这需要添加一个开关，防止重复请求
    if (isRefreshing) {
      refreshTokenRequst()
    }
    isRefreshing = false
    // 这个Promise函数很关键
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber(() => {
        resolve(request(url, options))
      })
    })
    return retryOriginalRequest
  } else {
    return response
  }
}

function refreshTokenRequst () {
  const data = {
    userName: 'euRest',
    password: 'euRest123456',
    // password: '123456',
    loginName: '王莉莉'
  }
  axios.request({
    baseURL: '',
    url: 'api/seeyon/rest/token',
    method: 'POST',
    data
  }).then((response) => {
    localStorage.setItem('token', response.data.id)
    location.reload()
    onAccessTokenFetched()
    isRefreshing = true
  })
}

let subscribers = []
function onAccessTokenFetched () {
  subscribers.forEach((callback) => {
    callback()
  })
  subscribers = []
}

function addSubscriber (callback) {
  subscribers.push(callback)
}
