/**
 * api接口统一管理
 */
import { post, get } from './request'

/**
 * @param {{}} params
 * 我们定义了一个topics方法
 * 这个方法有一个参数params
 * params是我们请求接口时携带的参数对象
 * 而后调用了我们封装的post方法
 * post方法的第一个参数是我们的接口地址
 * 第二个参数是topics的params参数
 * 即请求接口时携带的参数对象
 * 最后通过export导出topics。
 */

export const VtPhone = (params, success) => post('seeyon/rest/eu/cz', params, success) // 验证手机号

export const privateRge = (params, success) => post('seeyon/rest/eu/cz', params, success) // 个人注册

export const enterprise = (params, success) => post('seeyon/rest/eu/cz', params, success) // 企业注册
// 首页获取token
export const tokenGet = (params, success) => post('seeyon/rest/token', params, success) // 获取token

export const exitLogin = (params, success) => post('seeyon/rest/eu/cz', params, success) // 退出登录

export const MyEnterprise = (params, success) => post('seeyon/rest/eu/cz', params, success) // 企业介绍

export const RecruitingAt = (params, success) => post('seeyon/rest/eu/cz', params, success) // 招募公告

export const MyNews = (params, success) => post('seeyon/rest/eu/cz', params, success) // 我的消息

export const ComPlaint = (params, success) => post('seeyon/rest/eu/cz', params, success) // 查询申诉记录

export const EnMe = (params, success) => post('seeyon/rest/eu/cz', params, success) // 查询企业信息维护

export const signRecord = (params, success) => post('seeyon/rest/eu/cz', params, success) // 查询报名记录

export const RegRedTerms = (params, success) => post('seeyon/rest/eu/cz', params, success) // 注册条款

export const Winning = (params, success) => post('seeyon/rest/eu/cz', params, success) // 中标公示

export const updatePrice = (params, success) => post('seeyon/rest/eu/cz', params, success) // 保存报价

export const uploadFile = (params, File, success) => get('/seeyon/eu.do?' + params, File, success) // 文件下载

export const saveHome = (params, success) => post('seeyon/rest/eu/cz', params, success) // 保存注册用户

export const delUsers = (params, success) => post('seeyon/rest/eu/cz', params, success) // 删除子账户

export const addAttchment = (params, success) => post('seeyon/rest/euAttachment', params, success) // 保存注册用户

export const updateEnterprise = (params, success) => post('seeyon/rest/eu/cz', params, success) // 修改企业账户信息

export const updatePerson = (params, success) => post('seeyon/rest/eu/cz', params, success) // 修改个人账户信息

export const getType = (params, success) => post('seeyon/rest/eu/cz', params, success) // 查询发票类型

export const isPhoneCli = (params, success) => post('seeyon/rest/eu/cz', params, success) // 查询手机号是否以注册

export const getPrice = (params, success) => post('seeyon/rest/eu/cz', params, success) // 获取报价/查询是否报名

export const apply = (params, success) => post('seeyon/rest/eu/cz', params, success) // 报名

export const appealIn = (params, success) => post('seeyon/rest/eu/cz', params, success) // 申诉

export const getBlacklistByApi = (params, success) => post('seeyon/rest/eu/cz', params, success) // 查询是否是黑名单用户

// export const fileDel = (params, success) => post('seeyon/rest/euAttachment', params, success) // 删除附件
export const fileDel = (params, success) => post('seeyon/rest/eu/cz', params, success) // 删除附件

export const getContentApi = (params, success) => post('seeyon/rest/eu/cz', params, success) // 获取经营内容

export const getNumberApi = (params, success) => post('seeyon/rest/eu/cz', params, success) // 判断纳税号是否存在
